/*
$(document).ready(function(){

	//Init
	if (!Cookies.get('p2_email_dismiss_count') ) {
		Cookies.set('p2_email_dismiss_count',0); //Set Counter
	}
	
	if (!Cookies.get('p2_email_dismiss') ) {
		Cookies.set('p2_email_dismiss',"false"); //Set Display Status
	}
	
	//Toggle
	if (Cookies.get('p2_email_dismiss') == "false") {
		console.log("Showing modal");
		$('#signup-modal').modal('show');
	}
	
	//Increment
	$('#signup-modal').on('hidden.bs.modal', function () {
		
		var count = parseInt(Cookies.get('p2_email_dismiss_count')) + 1;
		
		Cookies.set('p2_email_dismiss_count', count, {expires : 365});
		
		Cookies.set('p2_email_dismiss','true',{expires : 1}); //Hide the modal for a day.
		
	});
	
});

//User Subscribed
$(document).on('click','.disable-modal', function () {
	Cookies.set('p2_email_dismiss_count', 5, {expires : 365});
	Cookies.set('p2_email_dismiss', 'true', {expires : 365});
});
*/