$(document).ready(function(){

	$("img").unveil(200, function() {
		
		$(this).load(function() {
			$(this).removeClass('preload');
		});		
		
	});
		
	//$('#voter-modal').modal('show');
	
});

/*
$(document).on('click', '.enter-now', function(){
	
	$('#voter-modal').modal('hide');
	
	Cookies.set('p2_alec_voter_status','hide',{ expires: 365 });
	
	$('html,body').animate({scrollTop: $("#voter").offset().top},'slow');
	
});
*/


// Email Signup Modal
$(document).ready(function(){
	$("#signup-modal").emailSignup("krux_trucks")
});

$(document).on('click', '.disable-modal', function() {	
	$("#signup-modal").emailSignupDisable("krux_trucks");
});